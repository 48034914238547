<template>
  <el-card>
    <div slot="header">
      <span>下单时间：</span>
      &emsp;
      <el-date-picker
        v-model="dateTimeRange"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束日期"
        :default-time="defaultTime"
        value-format="yyyy-MM-dd HH:mm:ss"
        clearable
        style="vertical-align: middle"
        @change="formatDataTime"
      ></el-date-picker>
      &emsp;
      <span>用户手机号：</span>
      &emsp;
      <el-input
        v-model="queryParams.cellphone"
        class="filter-item"
        style="width: 150px"
        clearable
        placeholder="请输入用户手机号"
      ></el-input>
      &emsp;
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="fetchData"
        >搜索</el-button
      >
      <el-button class="filter-item" type="success" icon="el-icon-refresh" @click="fetchData"
        >刷新</el-button
      >
      <el-button class="filter-item" type="warning" icon="el-icon-download" @click="exportData"
        >导出</el-button
      >
    </div>
    <div>
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="参与用户" name="first"></el-tab-pane>
        <el-tab-pane label="中奖用户" name="second"></el-tab-pane>
      </el-tabs>
      <el-table :data="tableData" border header-align="center" highlight-current-row>
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column label="用户手机号" prop="cellphone">
          <template #default="{ row }">
            {{ row.cellphone ? row.cellphone.replace(/^(\d{3})\d{4}(\d{4})$/, "$1****$2") : "-" }}
          </template>
        </el-table-column>

        <template v-if="activeName === 'first'">
          <el-table-column label="参与时间" prop="ptime" />
        </template>
        <template v-if="activeName === 'second'">
          <el-table-column label="中奖时间" prop="win_time" />
          <el-table-column label="奖品类型" prop="prize_type" :formatter="prizeTypeFormatter" />
          <el-table-column label="奖品名称" prop="prize_name" />
          <el-table-column prop="address.detail" label="地址信息" />
        </template>

        <!-- <el-table-column v-if="activeName === 'second'" label="表单ID" prop="form_id" /> -->
        <!-- <el-table-column v-if="activeName === 'second'" label="奖品数量" prop="prize_conut" /> -->
        <!-- <el-table-column label="订单编号" prop="order_number" /> -->
      </el-table>
    </div>
    <div class="pagination-container">
      <pagination
        :total="total"
        :page.sync="pagination.page"
        :limit.sync="pagination.size"
        @pagination="fetchData"
      />
    </div>
  </el-card>
</template>

<script>
import {
  getStatisticalParticipateUser,
  getStatisticalWinUser,
  exportParticipateUser,
  exportWinUser,
} from "@/api/luckDraw";
import { downloadFile } from "@/utils";
export default {
  props: {
    uuid: {
      type: String,
    },
  },
  data() {
    return {
      tableData: [],
      dateTimeRange: [],
      defaultTime: ["00:00:00", "23:59:59"],
      queryParams: {
        cellphone: null,
        start_time: null,
        end_time: null,
      },
      pagination: {
        page: 0,
        size: 10,
      },
      activeName: "first",
      total: 0,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      let result = null;
      const reqParams = { ...this.pagination, ...this.queryParams };
      if (this.activeName === "first") {
        result = await getStatisticalParticipateUser(this.uuid, reqParams);
      } else {
        result = await getStatisticalWinUser(this.uuid, reqParams);
      }
      if (result) {
        this.tableData = result.data.results;
        this.total = result.data.count;
      }
    },
    exportData() {
      if (this.activeName === "first") {
        exportParticipateUser(this.uuid, this.queryParams).then((res) => {
          downloadFile(res, "参与用户数据", "xlsx");
        });
      } else {
        exportWinUser(this.uuid, this.queryParams).then((res) => {
          downloadFile(res, "中奖用户数据", "xlsx");
        });
      }
    },
    formatDataTime(v) {
      if (v) {
        this.queryParams.start_time = this.dateTimeRange[0];
        this.queryParams.end_time = this.dateTimeRange[1];
      } else {
        this.queryParams.start_time = "";
        this.queryParams.end_time = "";
      }
    },
    handleClick() {
      console.log("this.activeName", this.activeName);
      this.fetchData();
    },
    prizeTypeFormatter(row) {
      let typeName = "";
      switch (row.prize_type) {
        case 1:
          typeName = "优惠券";
          break;
        case 2:
          typeName = "课程";
          break;
        case 3:
          typeName = "实物";
          break;
        case 4:
          typeName = "其他";
          break;
        default:
          break;
      }
      return typeName;
    },
  },
};
</script>

<style lang="less" scoped></style>
